import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { ProductType } from 'Type/ProductList.type';

import {
    LEASE_PERIODS,
    MONTHS_IN_YEAR,
    RETURN_LAST_CHARACTERS
} from './ProductLease.config';

import './ProductLease.style';

/** @namespace Scandipwa/Component/ProductLease/Component */
export class ProductLeaseComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        code: PropTypes.string.isRequired,
        handlePeriodChange: PropTypes.func.isRequired,
        monthlyPayment: PropTypes.number.isRequired,
        selectedPeriod: PropTypes.number.isRequired,
        leasingInterestRate: PropTypes.number.isRequired,
        euriborRate: PropTypes.number.isRequired,
        euriborDate: PropTypes.string.isRequired
    };

    renderLeaseElement() {
        const {
            product,
            code,
            handlePeriodChange,
            monthlyPayment,
            selectedPeriod,
            leasingInterestRate,
            euriborRate,
            euriborDate
        } = this.props;

        const elfsightID = {
            lv: '1aca1a7c-346b-44b2-bcd8-5f974c746a28',
            en: 'd283609b-3cca-4efb-950d-74944aa79feb',
            ru: '8b1d85f8-56d1-4570-af38-d66d281c1650',
            lt: 'a1665994-4fab-4eca-8ad8-5902e8da6a48',
            ee: 'd3623d4e-b038-4996-825a-40987c358bc5'
        };

        const usedCarElfsightID = {
            en: 'a80c7746-d2ab-4950-a929-97a29bd78c94',
            ee: '38c27b0b-63c8-45fc-8a46-ace9e269572c'
        };

        const language = code.includes('_') ? code.slice(RETURN_LAST_CHARACTERS) : code;
        const percent = leasingInterestRate + euriborRate;

        const monthlyPaymentElement = monthlyPayment > 0 ? (
            <span id="monthlyPayment">
                { monthlyPayment }
                { __('EUR') }
            </span>
        ) : (
            <span id="monthlyPayment">
                { __('Please select a period and calculate') }
            </span>
        );

        return (
            <div block="ProductLease">
                <h2 block="ProductLease" elem="Heading">
                    { __('Leasing calculator') }
                </h2>

                <div block="ProductLease" elem="Calculator">
                    <div>
                        <p block="ProductLease" elem="SubHeading">{ __('Leasing period, years') }</p>
                    </div>
                    <div block="ProductLease" elem="SelectPeriod">
                        { LEASE_PERIODS.map((period) => (
                            <Field
                              key={ period }
                              mix={ { block: 'ProductLease', elem: 'Period' } }
                              attr={ {
                                  id: `leasingPeriod-${period}`,
                                  name: 'leasingPeriod',
                                  checked: selectedPeriod === period
                              } }
                              type="radio"
                              value={ period }
                              events={ { onChange: () => handlePeriodChange(period) } }
                              label={ `${period / MONTHS_IN_YEAR}` }
                            />
                        )) }
                    </div>
                    <div block="ProductLease" elem="MonthlyPayment">
                        <h2>
                            { __('Monthly payment: ') }
                            { monthlyPaymentElement }
                        </h2>
                    </div>
                    <div block="ProductLease" elem="LeaseActions">
                        <button block="Button ShowForm" data-elfsight-show-form={ elfsightID[language] }>
                            { __('Apply') }
                        </button>
                        <div className="hidden" data-elfsight-show-form={ usedCarElfsightID[language] } />
                        <div id="car-sku" className="hidden" data-value={ product.sku } />
                        <div id="car-name" className="hidden" data-value={ product.name } />
                    </div>
                    <hr />
                    <div block="ProductLease" elem="Notice">
                        <div>
                            { __('Down payment 10%') }
                        </div>
                        <div>
                            { __(
                                '%s% + 6m Euribor (Euribor value %s%: %s%) in total - %s%',
                                leasingInterestRate,
                                euriborDate,
                                euriborRate,
                                percent
                            ) }
                        </div>
                        <div>
                            <div className="with-tooltip">
                                <span>
                                    { __(
                                        'The calculation of the calculator is informative '
                                        + 'and made based on an approximate calculation'
                                    ) }
                                </span>
                                <div className="tooltip">
                                    <div className="message">
                                        { __('When purchasing a car under operational leasing for 5 years,'
                                            + ' the value of which is EUR 34,900.00,'
                                            + ' and making a down payment of 10% of the purchase value,'
                                            + ' the applied interest rate: 1.89% + 6 months.'
                                            + ' Euribor (Euribor value 15.05.2024: 3.798%)) and residual value is 40%,'
                                            + ' commission: 1.5% of the purchase value of the car,'
                                            + ' total repayable amount: EUR 28,072.89'
                                            + ' (excluding the down payment and including the residual value)'
                                            + ' with EUR 400.99 monthly payment and annual interest rate (APR): 6.672%.'
                                            + ' The data calculated in the example are informative and may change after'
                                            + ' consideration of the leasing application.') }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return this.renderLeaseElement();
    }
}

export default ProductLeaseComponent;
